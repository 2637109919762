import { Controller } from "stimulus"

// There is currently no pagination on the assessments index page, so we can
// implement search entirely on the front-end.
export default class extends Controller {
  static targets = ["assessment"]

  // When search value changes, hide rows that don't match and unhide those that do
  search(event) {
    const value = (event.target.value || "").toLowerCase()
    this.assessmentTargets.forEach((el) => {
      if (el.dataset.name.toLowerCase().includes(value)) {
        el.classList.remove("d-none")
      } else {
        el.classList.add("d-none")
      }
    })
  }
}
